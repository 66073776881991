import { getNextStaticProps } from "@faustjs/next";
import { GetStaticPropsContext } from "next";
import { useFeaturedPost, useMenu, useGeneralOptionsSettings } from "hooks";
import React from "react";
import {
  Hero,
  WorkSlider,
  AlternatingContent,
  FeaturedPost,
  ServicesListing,
} from "components";
import { client, PageIdType } from "client";
import Layout from "layouts/main";

export default function Page() {
  const { usePage, useQuery } = client;
  const featuredPost = useFeaturedPost();
  const headerLinks = useMenu("PRIMARY");
  const footerLinks = useMenu("FOOTER");
  const works = useQuery().works({ first: 4 }).nodes;
  // const services = useQuery().services()?.nodes;
  const page = usePage({
    id: `/`,
    idType: PageIdType.URI,
  });
  const options = useGeneralOptionsSettings();
  const ceoLink = options?.generalSettings?.ceoLink;

  return (
    <Layout page={page} headerLinks={headerLinks} footerLinks={footerLinks}>
      <main className="content">
        <Hero
          heading={page?.contentHero?.heading}
          content={page?.contentHero?.content}
          link={ceoLink}
        />
        {page?.features?.features && (
          <AlternatingContent features={page?.features?.features} />
        )}
        {works && <WorkSlider works={works} />}
        {/* {services && <ServicesListing services={services} />} */}
        <FeaturedPost post={featuredPost} />
      </main>
    </Layout>
  );
}

export async function getStaticProps(context: GetStaticPropsContext) {
  return getNextStaticProps(context, {
    Page,
    client,
  });
}
